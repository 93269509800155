<template>
  <div class="circleManagePeson"
  >
    <pageHead class="messageMainView_head" title="全部成员" />
    <div v-if="userMemberIdentity >=3" @click.prevent="toSearch">
      <SearchBox placeholder="搜索圈子内成员"></SearchBox>
    </div>
    <div v-if="userMemberIdentity < 3" class="tabBar">
      <div class="bar_01" @click.prevent="toSearch">
        <van-icon size="25" name="search" />
      </div>
      <div class="bar_02" v-if="userMemberIdentity <3">
        <div class="bar_02_1" v-for="item in tabList" :key="item.type">
          <div :class="currentIndex === item.type ? 'active' : 'active_no'" @click="tabBarClick(item.type)">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="bar_04" v-if="userMemberIdentity <3" @click.stop="ManageCircle">管理</div>
      <!--  todo    管理选项-->
      <van-action-sheet
        v-model="ManageShow"
        :actions="ManageManageActions"
        @cancel="onCancel"
        cancel-text="取消"
        close-on-click-action
        @select="manageOnSelect" />
    </div>

    <div
      class="loadMore"
      v-infinite-scroll="loadMore"
      infinite-scroll-throttle-delay="500"
      infinite-scroll-distance="50">
      <div class="personList" v-if="currentIndex === 0">
        <!-- todo 全部成员列表-->
        <circleManagePesonListCont
          :memberList="memberList"
          @forbidden="handleForbidden"
          @reload="reloadMemberList"
        ></circleManagePesonListCont>
        <no-more v-if="listQueryParams.hasNextPage===false && memberList.length>0" text="无更多成员" />
        <van-empty v-if="listQueryParams.hasNextPage===false && memberList.length===0" description="无成员加入" />
      </div>
      <div v-else>
        <!-- todo 禁言成员列表-->
        <mutedList
          @reload="handleMutedList"
          :mutedList="mutedList"
        ></mutedList>
        <no-more v-if="listQueryParams.hasNextPage===false && mutedList.length>0" />
        <van-empty v-if="listQueryParams.hasNextPage===false && mutedList.length===0" description="无禁言成员" />
      </div>
    </div>

  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import circleManagePesonListCont from './circleManagePesonList.vue'
import mutedList from './mutedList.vue'
import {getMemberIdentity, listCircleMember, listMutedMember} from "@/api/circle";
import NoMore from "@/components/uitls/NoMore";
import SearchBox from '@/components/searchBox'
import {getItem, setItem} from "@/utils/storage";

export default {
  name: 'circleManagePeson', // 圈子管理禁言人员管理
  components: {
    NoMore,
    pageHead,
    circleManagePesonListCont,
    mutedList,
    SearchBox
  },
  data () {
    return {
      currentIndex: 0,
      ManageShow: false,

      allManageActions: [
        // {
        //   name: '圈主转移',
        //   key: 'circleMaster'
        // },
        // {
        //   name: '圈管理员设置',
        //   key: 'manageSetting'
        // },
        {
          name: '添加禁言成员',
          key: 'addForbiddenMember'
        },
        // {
        //   name: '禁言成员',
        //   key: 'forbiddenWords'
        // },
        {
          name: '添加成员',
          key: 'addMember'
        },
        {
          name: '删除成员',
          key: 'deleteMember',
          color: '#ee0a24'
        }
      ],
      // 禁言管理选项
      mutedManageActions: [
        {
          name: '添加禁言成员',
          key: 'addForbiddenMember'
        },
        {
          name: '解除禁言成员',
          key: 'deleteForbiddenMember',
          color: '#ee0a24'
        }
      ],
      ManageManageActions: [],
      tabList: [
        {
          title: '全部成员',
          type: 0,
        },
        {
          title: '禁言成员',
          type: 1,
        }
      ],
      noDataInfo: {
        show: false,
        title1: '暂未禁言成员'
      },
      memberList: [], // 成员列表中的所有数据
      mutedList: [], // 被禁言成员列表
      userMemberIdentity: null, // 用户在圈子中的身份
      listQueryParams: {
        "circleId": this.$route.query.circleId,
        "pageNum": 1,
        "pageSize": 20,
        hasNextPage: true,
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    next((that) => {
      if (from.name === 'circleXq' || from.name === 'circleJbglsz') {
        that.currentIndex = 0
      } else {
        const currentIndex = getItem('memberManageCurrentIndex')
        if (currentIndex) {
          that.currentIndex = currentIndex
        } else {
          that.currentIndex = 0
        }
      }
    })
  },
  methods: {
    loadMore () {
      this.getMemberIdentity().then(() => {
        if (this.currentIndex === 0) {
          this.loadList()
        } else {
          this.loadMuteList();
        }
      })
    },
    // 加载用户信息
    getMemberIdentity () {
      return new Promise((resolve) => {
        if (this.userMemberIdentity !== null) {
          resolve(this.userMemberIdentity)
          return
        }
        getMemberIdentity({circleId: this.$route.query.circleId}).then(res => {
          this.userMemberIdentity = res.identity
          resolve()
        }).catch(() => {
          this.userMemberIdentity = 3;
          resolve()
        })
      })
    },
    // 加载列表
    loadList () {
      let listQueryParams = this.listQueryParams
      if (listQueryParams.hasNextPage) {
        listCircleMember(this.listQueryParams).then((res) => {
          listQueryParams.hasNextPage = res.current < res.pages
          listQueryParams.pageNum = listQueryParams.pageNum + 1
          let resData = this.memberList
          res.records.forEach((item) => {
            let count = resData.filter(
              (innerItem) => innerItem.userBasicVO.userId === item.userBasicVO.userId
            )
            if (count.length === 0) {
              resData.push(item)
            }
          })
          this.memberList = resData
        })
      }
    },

    // 加载列表
    loadMuteList () {
      let listQueryParams = this.listQueryParams
      if (listQueryParams.hasNextPage) {
        listMutedMember(this.listQueryParams).then((res) => {
          listQueryParams.hasNextPage = res.current < res.pages
          listQueryParams.pageNum = listQueryParams.pageNum + 1
          let resData = this.memberList
          res.records.forEach((item) => {
            let count = resData.filter(
              (innerItem) => innerItem.userBasicVO.userId === item.userBasicVO.userId
            )
            if (count.length === 0) {
              resData.push(item)
            }
          })
          this.mutedList = resData
        })
      }
    },

    tabBarClick (type) {
      this.currentIndex = type
      this.memberList = []
      this.mutedList = []
      this.listQueryParams.pageNum = 1;
      this.listQueryParams.hasNextPage = true;
      this.loadMore()
    },
    // 管理
    ManageCircle () {
      if (this.currentIndex === 0) {
        this.ManageManageActions = this.allManageActions
      } else {
        if (this.mutedList.length > 0) {
          this.ManageManageActions = this.mutedManageActions
        } else {
          this.ManageManageActions = [
            {
              name: '添加禁言成员',
              key: 'addForbiddenMember'
            }
          ]
        }
      }
      this.ManageShow = true
    },
    // 跳转搜索人员页面
    toSearch () {
      this.$router.push({
        name: "circlePersonSearch",
        query: {circleId: this.$route.query.circleId, type: this.currentIndex}
      });
    },
    // 禁言
    handleForbidden () {
      // userId
      this.reloadMemberList()
      // this.memberList = this.memberList.map(item => {
      //   if (item.userBasicVO.userId === userId) {
      //     return {
      //       ...item,
      //       isShowMuted: false,
      //     }
      //   } else {
      //     return item
      //   }
      // })
    },
    // 解除管理员
    reloadMemberList () {
      this.memberList = [];
      this.mutedList = [];
      this.listQueryParams.pageNum = 1;
      this.listQueryParams.hasNextPage = true;
      this.loadMore()
    },
    // 解除管理员
    handleMutedList () {
      // this.mutedList = this.mutedList.filter(item => item.userBasicVO.userId !== id)
      this.memberList = [];
      this.mutedList = [];
      this.listQueryParams.pageNum = 1;
      this.listQueryParams.hasNextPage = true;
      this.loadMore()
    },
    // 禁言
    vanSwipeJy (id) {
      console.log(id)
      this.$dialog.confirm({
        title: '禁言成员',
        message: '你确定要禁言该成员吗？禁言后 该成员无法在圈内发帖',
        confirmButtonText: '禁言成员',
        cancelButtonText: '再考虑下'
      });
    },

    // 获取管理下标
    manageOnSelect (value) {
      switch (value.key) {
        case 'circleMaster':
          // 圈主转移
          this.$router.push({name: "circleZr", query: {id: this.$route.query.circleId}})
          break
        case 'manageSetting':
          // 圈管理员设置
          this.$router.push({name: "manageSetting", query: {circleId: this.$route.query.circleId}})
          break
        case 'forbiddenWords':
          // 禁言成员
          this.tabBarClick(1)
          // this.$router.push({name: "circleZr", query: {id: this.$route.query.circleId}})
          break
        case 'addMember':
          // 添加成员
          this.$router.push({name: "addCircleMember", query: {circleId: this.$route.query.circleId}})
          break
        case 'deleteMember':
          // 删除成员
          this.$router.push({name: "deleteCircleMember", query: {circleId: this.$route.query.circleId}})
          break
        case 'addForbiddenMember':
          // 添加禁言成员
          this.$router.push({
            name: "deleteCircleMember",
            query: {
              circleId: this.$route.query.circleId,
              sourceType: 'addMuted'
            }
          })
          break
        case 'deleteForbiddenMember':
          // 解除禁言成员
          this.$router.push({
            name: "deleteCircleMember",
            query: {
              circleId: this.$route.query.circleId,
              sourceType: 'deleteMuted'
            }
          })
          break
        default:
          console.log("未识别到处理方法")
      }
      /*  if (i === 0) {
          this.$router.push({name: "circleJyPerContAdd"})
        } else {
          this.$router.push({name: "circleJyPerContCancal"})
        }*/
    },
    // 取消
    onCancel () {
      this.ManageShow = false
    }
  },
  beforeDestroy () {
    setItem('memberManageCurrentIndex', this.currentIndex)
  }
}
</script>

<style lang="scss" scoped>
.circleManagePeson {
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;

  .pageHead {
    box-shadow: none;
  }

  .tabBar {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    height: 44px;
    align-items: center;

    .bar_01 {
      width: 17.5px;
      display: inline-block;
      position: relative;
      top: 0;
    }

    .bar_02 {
      font-size: 16px;
      line-height: 16px;

      .bar_02_1 {
        display: inline-block;
        margin-right: 32px;
      }

      .bar_02_1:last-child {
        margin-right: 0;
      }
    }

    .bar_04 {
      font-size: 13px;
      color: #fe5800;
      line-height: 13px;
    }
  }
}

.loadMore {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-bottom: 110px;
}

.active {
  color: #000;
  position: relative;

  &::before {
    width: 22px;
    height: 4px;
    content: url('~@/assets/imgs/circle/Slice5s.svg');
    position: absolute;
    left: 17px;
    top: 12px;
  }
}

.active_no {
  color: #6D7278;
}

</style>
